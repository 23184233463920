import { Dispatch } from '@reduxjs/toolkit';
import { plaidApi } from 'store/api';
import { apiSlice } from './apiSlice';
import {
  ICreateAccessTokenParams,
  ICreateLinkTokenResponse,
  ICreateFinancialLinkTokenParams,
  ICreateIncomeLinkTokenParams,
  IGetStatusResponse,
  ICreateConsumerReportLinkTokenParams,
} from 'api/digifi/PlaidApi';
import { createNotification } from 'handlers/notificationsSlice';

const PLAID_ERROR_MESSAGE = 'Unable to connect. Please try again later.';

const createPlaidErrorNotification = (dispatch: Dispatch) => {
  createNotification({
    type: 'error',
    notification: PLAID_ERROR_MESSAGE,
    dispatch,
  });
};

export const plaidApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createIncomeLinkToken: builder.mutation<
      ICreateLinkTokenResponse,
      ICreateIncomeLinkTokenParams
    >({
      queryFn: async (params: ICreateIncomeLinkTokenParams, { dispatch }) => {
        try {
          const data = await plaidApi.createIncomeLinkToken(params);

          return { data };
        } catch (error) {
          createPlaidErrorNotification(dispatch);

          return { error };
        }
      },
    }),
    createFinancialLinkToken: builder.mutation<
      ICreateLinkTokenResponse,
      ICreateFinancialLinkTokenParams
    >({
      queryFn: async (params: ICreateFinancialLinkTokenParams, { dispatch }) => {
        try {
          const data = await plaidApi.createFinancialLinkToken(params);

          return { data };
        } catch (error) {
          createPlaidErrorNotification(dispatch);

          return { error };
        }
      },
    }),
    createConsumerReportLinkToken: builder.mutation<
      ICreateLinkTokenResponse,
      ICreateConsumerReportLinkTokenParams
    >({
      queryFn: async (params: ICreateConsumerReportLinkTokenParams, { dispatch }) => {
        try {
          const data = await plaidApi.createConsumerReportLinkToken(params);

          return { data };
        } catch (error) {
          createPlaidErrorNotification(dispatch);

          return { error };
        }
      },
    }),
    createAccessToken: builder.mutation<
      null,
      ICreateAccessTokenParams
    >({
      queryFn: async (params: ICreateAccessTokenParams, { dispatch }) => {
        try {
          await plaidApi.createAccessToken(params);

          return { data: null };
        } catch (error) {
          createPlaidErrorNotification(dispatch);

          return { error };
        }
      },
    }),
    getPlaidStatus: builder.query <
      IGetStatusResponse,
      void
    >({
      queryFn: async () => {
        try {
          const data = await plaidApi.getStatus();

          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useCreateIncomeLinkTokenMutation,
  useCreateFinancialLinkTokenMutation,
  useCreateConsumerReportLinkTokenMutation,
  useCreateAccessTokenMutation,
  useGetPlaidStatusQuery,
} = plaidApiSlice;
