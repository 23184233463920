export enum ErrorCode {
  ApplicationPermissionsVerificationFailure = 'application_permissions_verification_failure',
  ModifyApplicationInStatusPermissionDenied = 'modify_application_in_status_permission_denied',
  EditApplicationPermissionDenied = 'edit_application_permission_denied',
  AuthorNotFound = 'author_not_found',
  PasswordValidationTokenMissing = 'password_validation_token_missing',
  TokenInvalidOrAccountDeleted = 'token_invalid_or_account_deleted',
  PasswordValidationTokenInvalid = 'password_validation_token_invalid',
  EmailAlreadyInUse = 'email_already_in_use',
  AuthTokenInvalid = 'auth_token_invalid',
  AccountMissingPhoneNumber = 'account_missing_phone_number',
  PhoneNumberNotAssociatedWithAccount = 'phone_number_not_associated_with_account',
  InvitationTokenInvalidOrExpired = 'invitation_token_invalid_or_expired',
  AccountInvalidOrDeleted = 'account_invalid_or_deleted',
  BorrowerNotFound = 'borrower_not_found',
  AccountDoesNotExist = 'account_does_not_exist',
  ResetPasswordLinkNotConfigured = 'reset_password_link_not_configured',
  AccountAlreadyExists = 'account_already_exists',
  IntermediaryNotFound = 'intermediary_not_found',
  ApplicationStatusNotProvided = 'application_status_not_provided',
  TeamMembersNotFound = 'team_members_not_found',
  ProvidedLabelsNotFound = 'provided_labels_not_found',
  BorrowerAndCoBorrowerCannotBeSame = 'borrower_and_coborrower_cannot_be_same',
  CoBorrowerCannotBeSame = 'co_borrower_cannot_be_same',
  BorrowerTypeNotAllowedForProduct = 'borrower_type_not_allowed_for_product',
  BorrowerLockedForNewApplications = 'borrower_locked_for_new_applications',
  CannotAddLockedBorrowerToApplication = 'cannot_add_locked_borrower_to_application',
  PhoneNumberAlreadyInUse = 'phone_number_already_in_use',
  CodeNotValid = 'code_not_valid',
  UpdatePhoneNumberNotSupportedForMultiplePhoneNumbers = 'update_phone_number_not_supported_for_multiple_phone_numbers',
  CodeAlreadyWasSent = 'code_already_was_sent',
  Unauthorized = 'unauthorized',
  DocuSignIntegrationNotConnected = 'docusign_integration_not_connected',
  MfaCodeVerificationFailed = '456',
}
