import BaseApi, { FetchMethodType } from 'api/BaseApi';

export enum DocuSignEnvironmentType {
  Development = 'development',
  Production = 'production'
}

export interface IDocusignConnectionStatus {
  connected: boolean;
}

export interface IEmbeddedSigningData {
  embeddedSigningUrl: string;
  clientId?: string;
  environment?: DocuSignEnvironmentType;
  waitingForOthers?: boolean;
}

export interface IDocuSignApi {
  getConnectionStatus(): Promise<IDocusignConnectionStatus>;
  createEmbeddedSigningData(applicationDisplayId: string): Promise<IEmbeddedSigningData>;
}

export default class DocuSignRestApi extends BaseApi implements IDocuSignApi {
  public getConnectionStatus(): Promise<IDocusignConnectionStatus> {
    return this.fetch<IDocusignConnectionStatus>('/docusign/connection-status');
  }

  public createEmbeddedSigningData(applicationDisplayId: string): Promise<IEmbeddedSigningData> {
    return this.fetch('/docusign/embedded-signing-data', {
      method: FetchMethodType.POST,
      body: {
        applicationDisplayId,
      },
    });
  }
}
