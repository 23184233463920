import BaseApi from 'api/BaseApi';

export interface IGoogleRecaptchaSiteSettings {
  siteKey: string;
}

export interface IRecaptchaApi {
  getSiteSettings(): Promise<IGoogleRecaptchaSiteSettings | null>;
}

export default class RecaptchaRestApi extends BaseApi implements IRecaptchaApi {
  public async getSiteSettings(): Promise<IGoogleRecaptchaSiteSettings | null> {
    const {settings } = await this.fetch<{
      settings: IGoogleRecaptchaSiteSettings | null;
    }>('/google-recaptcha/site-settings');

    return settings;
  }
}
