import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRecaptchaApi } from './ApiServiceProvider';

export type IExecuteCaptcha = () => Promise<string | null | undefined>;

const RecaptchaContext = createContext<{
  recaptchaSiteKey: string | null | undefined;
}>({
  recaptchaSiteKey: undefined,
});

export const useRecaptcha = () => {
  const { recaptchaSiteKey } = useContext(RecaptchaContext);

  const captchaRef = useRef<ReCAPTCHA>();

  const executeCaptcha = useCallback(async () => {
    return captchaRef.current?.executeAsync();
  }, [captchaRef]);

  const resetCaptcha = useCallback(() => {
    captchaRef.current?.reset();
  }, [captchaRef]);

  return {
    recaptchaSiteKey: recaptchaSiteKey || '',
    captchaRef,
    executeCaptcha,
    resetCaptcha,
  };
};

const RecaptchaProvider = ({ children }: PropsWithChildren<{}>) => {
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState<
    string | null | undefined
  >(undefined);

  const recaptchaApi = useRecaptchaApi();

  const loadSiteSettings = useCallback(async () => {
    const siteSettings = await recaptchaApi.getSiteSettings();

    if (siteSettings) {
      setRecaptchaSiteKey(siteSettings.siteKey);
    }
  }, []);

  useEffect(() => {
    loadSiteSettings();
  }, [loadSiteSettings]);

  return (
    <RecaptchaContext.Provider value={{ recaptchaSiteKey }}>
      {children}
    </RecaptchaContext.Provider>
  );
};

export default RecaptchaProvider;
