import { invitesApi } from 'store/api';
import { IBorrowerInviteInfo } from 'api/digifi/borrower-portal/BorrowerInvitesApi';
import { IIntermediaryInviteInfo } from 'api/digifi/intermediary-portal/IntermediaryInvitesApi';
import { apiSlice, ApiSliceTagType } from './apiSlice';
import { createSafeQueryFn } from './utils/queries';
import TokenUtils from 'utils/TokenUtils';
import { getItemInvalidationTag } from './utils/tags';

interface IAcceptAccountParams {
  payload: {
    email: string;
    phoneNumber: string;
    password: string;
    fullName?: string;
    recaptchaToken?: string | null | undefined;
  };
  token: string;
}

export const invitesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInviteInfo: builder.query<
      IBorrowerInviteInfo | IIntermediaryInviteInfo,
      string
    >({
      queryFn: createSafeQueryFn(async (token) => {
        return invitesApi.getInviteInfo(token);
      }),
    }),
    acceptInvite: builder.mutation<void, IAcceptAccountParams>({
      invalidatesTags: [getItemInvalidationTag('current', ApiSliceTagType.Account)],
      queryFn: createSafeQueryFn(async ({ payload, token }) => {
        const { accessToken, refreshToken } = await invitesApi.acceptInvite(payload, token);

        TokenUtils.setTokens({ accessToken, refreshToken });
      }),
    }),
  }),
});

export const {
  useGetInviteInfoQuery,
  useAcceptInviteMutation,
} = invitesSlice;
