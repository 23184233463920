import BaseApi, { FetchMethodType } from 'api/BaseApi';
import AppHeaders from 'enums/AppHeaders';

export interface IAuthResponse {
  accessToken: string;
  refreshToken: string;
}

export interface IBaseInviteInfo {
  email: string;
  phone: string;
}

export interface IBaseAcceptInviteParams {
  email: string;
  phoneNumber: string;
  password: string;
  recaptchaToken?: string | null;
}

export interface IBaseInvitesApi {
  acceptInvite(body: IBaseAcceptInviteParams, token: string): Promise<IAuthResponse>;
}

export default abstract class BaseInvitesRestApi extends BaseApi implements IBaseInvitesApi {
  protected resourceName = 'invites';

  public acceptInvite({ recaptchaToken, ...body }: IBaseAcceptInviteParams, token: string): Promise<IAuthResponse> {
    return this.fetch(`/${this.resourceName}/${token}`, {
      method: FetchMethodType.POST,
      body,
      headers: new Headers({
        ...(recaptchaToken ? { [AppHeaders.RecaptchaToken]: recaptchaToken } : {}),
      }),
    });
  };
}
