import { createAction, createSlice } from '@reduxjs/toolkit';
import { findApplicationTasks, submitTask } from './tasksSlice';
import { findApplicationByDisplayId } from './applicationsSlice';

export interface IApplicationDataState {
  selectedApplicationId: string | null;
  taskIds: string[] | null;
  previousApplicationStatusId: string | null;
  isSubmitInProgress: boolean;
  offersVariable: string | null;
  displayIdToRedirect: string | null;
}

const initialState: IApplicationDataState = {
  selectedApplicationId: null,
  taskIds: null,
  previousApplicationStatusId: null,
  isSubmitInProgress: false,
  offersVariable: null,
  displayIdToRedirect: null,
};

export const resetSelectedApplication = createAction('applicationData/resetSelectedApplication');
export const setApplicationData = createAction<Partial<IApplicationDataState>>('applicationData/setApplicationData');
export const setDisplayIdToRedirect = createAction<string>('applicationData/setDisplayIdToRedirect');
export const resetDisplayIdToRedirect = createAction('applicationData/resetDisplayIdToRedirect');

const applicationDataSlice = createSlice({
  name: 'applicationDataSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findApplicationByDisplayId.fulfilled, (state, { payload }) => {
        state.selectedApplicationId = payload.id;
      })
      .addCase(findApplicationTasks.pending, (state) => {
        state.taskIds = null;
      })
      .addCase(findApplicationTasks.fulfilled, (state, { payload }) => {
        state.taskIds = payload.map((task) => task.id);
      })
      .addCase(setApplicationData, (state, { payload }) => {
        Object.assign(state, payload);
      })
      .addCase(submitTask.fulfilled, (state, { payload }) => {
        state.taskIds = state.taskIds?.filter((taskId) => taskId !== payload.id) || null;
      })
      .addCase(resetSelectedApplication, (state) => {
        state.selectedApplicationId = null;
      })
      .addCase(setDisplayIdToRedirect, (state, { payload }) => {
        state.displayIdToRedirect = payload;
      })
      .addCase(resetDisplayIdToRedirect, (state) => {
        state.displayIdToRedirect = null;
      });
  },
});

export default applicationDataSlice.reducer;
