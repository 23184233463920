import { docuSignApi } from 'store/api';
import { IEmbeddedSigningData, IDocusignConnectionStatus } from 'api/digifi/DocuSignApi';
import { apiSlice } from './apiSlice';

export const docuSignSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocuSignConnectionStatus: builder.query<IDocusignConnectionStatus, void>({
      queryFn: async () => {
        try {
          const connectionStatus = await docuSignApi.getConnectionStatus();

          return { data: connectionStatus };
        } catch (error) {
          return { error };
        }
      },
      keepUnusedDataFor: 0,
    }),
    createEmbeddedSigningData: builder.mutation<
      IEmbeddedSigningData,
      string
    >({
      queryFn: async (applicationDisplayId: string) => {
        try {
          const data = await docuSignApi.createEmbeddedSigningData(applicationDisplayId);

          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useCreateEmbeddedSigningDataMutation,
  useGetDocuSignConnectionStatusQuery,
} = docuSignSlice;
