import { create } from '@yornaath/batshit';
import { portalPageElementsApi } from 'store/api';
import { IPortalPageElement } from 'api/digifi/PortalPageElementsApi';
import { IGenerateImageUrlsParams, IGenerateSelectionOptionUrlsParams } from 'api/digifi/portal-page-elements/BasePortalPageElementsApi';
import { apiSlice } from './apiSlice';

export const imageUrlsBatcher = create({
  fetcher: async (queries: IGenerateImageUrlsParams[]) => {
    const imageUrlsParams = queries.reduce(
      (combinedParams, params) => ({
        imageElementIds: [...(combinedParams.imageElementIds || []), ...(params.imageElementIds || [])],
      }),
      {} as IGenerateImageUrlsParams,
    );

    return portalPageElementsApi.generateImageUrls(imageUrlsParams);
  },
  resolver: (items) => items,
});

export const selectionOptionsUrlsBatcher = create({
  fetcher: async (queries: IGenerateSelectionOptionUrlsParams[]) => {
    const imageUrlsParams = queries.reduce(
      (combinedParams, params) => ({
        selectionOptionsElementIds: [...(combinedParams.selectionOptionsElementIds || []), ...(params.selectionOptionsElementIds || [])],
      }),
      {},
    );

    return portalPageElementsApi.generateSelectionOptionUrls(imageUrlsParams);
  },
  resolver: (items) => items,
});

export const portalPageElementsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPortalPageElements: builder.query<
      IPortalPageElement[],
      string
    >({
      queryFn: async (statusId) => {
        try {
          const result = await portalPageElementsApi.find({ statusId });

          return { data: result };
        } catch (error) {
          return { error };
        }
      },
    }),
    generatePortalPageImageUrls: builder.mutation<
      Record<string, string>,
      IGenerateImageUrlsParams
    >({
      queryFn: async (params) => {
        const result = await imageUrlsBatcher.fetch(params);

        return { data: result };
      },
    }),
    generatePortalPageSelectionOptionUrls: builder.mutation<
      Record<string, string>,
      IGenerateSelectionOptionUrlsParams
    >({
      queryFn: async (params) => {
        const result = await selectionOptionsUrlsBatcher.fetch(params);

        return { data: result };
      },
    }),
  }),
});

export const {
  useGetPortalPageElementsQuery,
  useGeneratePortalPageImageUrlsMutation,
  useGeneratePortalPageSelectionOptionUrlsMutation,
} = portalPageElementsSlice;
