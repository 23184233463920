import BaseApi, { BASE_API_PREFIX, FetchMethodType } from 'api/BaseApi';
import { stringifyRequestBody } from 'utils/fetch';
import AppHeaders from 'enums/AppHeaders';

interface ICreateTokenResponse {
  token: string;
}

export interface IAdminAuthApi {
  createToken(password: string, recaptchaToken?: string | null): Promise<string | null>;
  checkAccess(): Promise<void>;
}

// TODO: This class needs to be refactored.

export default class AdminAuthApi extends BaseApi implements IAdminAuthApi {
  public async checkAccess(): Promise<void> {
    await this.fetch('/admin-auth/check-access');
  }

  public async createToken(password: string, recaptchaToken?: string | null): Promise<string | null> {
    // We need to avoid using BaseApi here, since there we have logic
    // to handle 401/403 with redirections to sign in page

    const response = await fetch(`${BASE_API_PREFIX}/admin-auth/tokens`, {
      method: FetchMethodType.POST,
      body: stringifyRequestBody({
        password,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        ...(recaptchaToken ? { [AppHeaders.RecaptchaToken]: recaptchaToken } : {}),
      }),
    });

    if (!response.ok) {
      return null;
    }

    const responseData: ICreateTokenResponse = await response.json();

    return responseData.token;
  }
}
