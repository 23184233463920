import BaseApi from 'api/BaseApi';

export interface IGoogleAnalyticsSettings {
  measurementId: string;
}

export interface IGoogleAnalyticsApi {
  getMeasurementId(): Promise<string | null>;
}

export default class GoogleAnalyticsRestApi extends BaseApi implements IGoogleAnalyticsApi {
  public async getMeasurementId() {
    const { settings } = await this.fetch<{
      settings: IGoogleAnalyticsSettings | null;
    }>('/google-analytics');

    return settings ? settings.measurementId : null;
  }
}
